
import {
  Component, Vue, PropSync, Ref,
  Mixins,
  Watch
} from "vue-property-decorator";

import GetUserByRemote from "@/mixins/getUserByRemote";

import { addGroupShare } from "@/api/shareGroup";

import { phoneRegGlobal } from "@/utils/regular";

import inputPrice from "@/utils/inputPrice";

@Component({
  components: {
  },
})
export default class GroupAdd extends Mixins(GetUserByRemote) {
    @Ref("form") formRef: VForm;
    @PropSync("formVisible") visible: boolean;
    @PropSync("modelForm") formData: any;

    saveLoading = false;

    form = {
        id: "",
        name: "",
        phone: "",
        manager: "",
        users: [],
        remark: "",
        status: true,
        notes: "",
    }

    formRules = {
        name: [{ required: true, message: "请输入小组名称", trigger: "blur" }],
        manager: [{ required: true, message: "请输入小组负责人", trigger: "blur" }],
        phone: [{ required: true, pattern: phoneRegGlobal, message: "请输入小组负责人手机", trigger: "blur" }],
    }

    get dialogTitle() {
        return `${this.formData.id ? "编辑" : "新增"}小组`
    }

    inputPhone(val) {
        let phone = inputPrice(val, "number");
        this.form.phone = phone || "";
    }

    handleClose() {
        this.formRef?.resetFields();
        this.form.id = "";
        this.visible = false;
    }

    cancel() {
        this.handleClose();
    }

    confirm() {
        this.formRef?.validate().then(() => {
            const params = {
                ...this.form,
                users: this.form.users?.map(el => (el)),
                status: Number(this.form.status)
            }
            addGroupShare(params).then(() => {
                this.$message.success("操作成功");
                this.$emit("refreshList");
                this.handleClose();
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    @Watch("modelForm")
    setForm(newForm) {
        this.form = {
            ...newForm,
            users: newForm.managerList?.map((el: any) => el.id),
            status: Boolean(this.formData.status)
        }
        this.allUserList = newForm.managerList;
    }
}
