
import {
    Watch, Mixins, Component, PropSync
} from 'vue-property-decorator'

import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";


import ETable from '@/components/ETable.vue';
import AddComboForm from "./addComboForm.vue";

import { getGroupComboListByGroupId, addGroupCombo } from "@/api/shareGroup";

import { cloneDeep } from "lodash";

@Component({
    components: { ETable, AddComboForm }
})
export default class GroupCombo extends Mixins(loading, tablePagination, Resize) {
    @PropSync("visible", { default: false }) dialogVisible !: boolean;
    @PropSync("modelForm") formData: any;

    columns = [
        { label: "ID", prop: "id", minWidth: "120" },
        { label: "套餐名称", prop: "name", minWidth: "120"},
        { label: "套餐次数", prop: "num", minWidth: "120" },
        { label: "套餐适用设备型号", prop: "deviceTypeList", minWidth: "150"},
        { label: "状态", prop: "status", minWidth: "100"},
        { label: "添加人", prop: "modifiedBy", minWidth: "100"},
        { label: "添加时间", prop: "modifiedTime", minWidth: "100"},
        { label: "操作", prop: "action", minWidth: "100" },
    ]

    dataSource = []
    itemTotal = 0;

    addComboVisible = false;

    comboDetail = {};

    mounted() {
        
    }

    addCombo() {
        this.comboDetail = { status: true };
        this.addComboVisible = true;
    }

    dialogClose() {
        this.dialogVisible = false;
    }

    handleEdit(row) {
        this.comboDetail = cloneDeep(row);
        this.addComboVisible = true;
    }

    handleStatus(row) {
        // const 
        this.$confirm(`确定要${!row.status ? "启用" : "禁用"}套餐吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            addGroupCombo({ ...row, status: Number(!row.status) }).then(() => {
                this.getData();
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消'
            });          
        });
    }

    cancel() {
        this.dialogClose();
    }

    confirm() {

    }

    syncComboList() {
        this.getData();
    }

    @Watch("dialogVisible")
    getData() {
        if (!this.dialogVisible) return;
        const params = {
            groupId: this.formData.id,
            pageNum: this.currentPage,
            pageSize: this.pageSize,

        }
        getGroupComboListByGroupId(params).then((res) => {
            this.dataSource = res.data.list || [];
            this.dataSource?.forEach((el) => {
                el.deviceTypeList = el.deviceTypeNameList?.map(dt => dt.deviceTypeName)?.join("、")
            })
        })
    }
}

