
  import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";
  
  import ETable from "@/components/ETable.vue";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import { groupShareList } from "@/api/shareGroup";

  import GroupAdd from "./components/groupAdd.vue";
  import GroupRelationDevice from "./components/groupRelationDevice.vue";
  import GroupCombo from "./components/groupCombo.vue";

  import { formatTextarea } from "@/utils/common";

  import { cloneDeep } from "lodash";
  
  @Component({
      components: { ETable, GroupAdd, GroupRelationDevice, GroupCombo }
  })
  export default class ShareGroupList extends Mixins(tablePagination, loading, Resize) {
  [x: string]: any;

      formatTextarea = formatTextarea;
  
      ysbChecked = false;
  
      queryForm = {
          name: "",
          phone: "",
          comboName: "",
      };
  
      itemTotal = 0;
      tableData = [];
  
      columns = [
          { label: "ID", prop: "id" },
          { label: "小组名称", prop: "name", minWidth: "120" },
          { label: "小组负责人", prop: "manager", minWidth: "120" },
          { label: "负责人手机", prop: "phone", minWidth: "120" },
          { label: "小组简介", prop: "remark", minWidth: "180" },
          { label: "管理员", prop: "", formatter: (row) =>  row.managerList?.length},
          { label: "设备数", prop: "deviceNum" },
          { label: "套餐数", prop: "comboNum" },
          { label: "状态", prop: "status" },
          { label: "备注", prop: "notes", minWidth: "180" },
          { label: "操作人", prop: "modifiedBy" },
          { label: "最后操作时间", minWidth: "170", prop: "modifiedTime" },
          { label: "操作", width: "200", fixed: "right", prop: "action" },
      ];
  

      groupAddVisible = false
      relationDeviceVisible = false
      comboVisible = false

      form = {};

      mounted() {
          this.windowResize(310);
          this.getData();
      }
  
      handleSearch() {
          this.resetCurrent();
          this.getData();
      }

  
      getData() {
          this.showLoading();
          const params = {
              ...this.queryForm,
              pageNum: this.currentPage,
              pageSize: this.pageSize,
          }
          groupShareList(params).then(res => {
              this.tableData = res.data.list;
              this.itemTotal = res.data.total;
          }).finally(() => {
              this.hideLoading();
          })
      }
  
      goUserList(row) {
          this.$router.push({
              path: "/userMember",
              query: { phone: row.phone }
          })
      }
  
      viewCommentDetail(row) {
          this.pmsId = row.id;
          this.visible = true;
      }

      add() {
        this.form = { status: true };
        this.groupAddVisible = true;
      }

      edit(row) {
        this.groupAddVisible = true;
        this.form = cloneDeep(row);
      }
      relationDevice(row) {
        this.form = row;
        this.relationDeviceVisible = true;
      }
      managerCombo(row) {
        this.form = row;
        this.comboVisible = true;
      }


  }
  